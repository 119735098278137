/* eslint-disable no-underscore-dangle */
import { atom } from 'recoil';

// eslint-disable-next-line no-unused-vars
const localStorageEffect =
  (project, key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(project);
    if (savedValue != null) {
      setSelf(
        JSON.parse(savedValue)[key] || {
          value: key === 'CurrentDate' ? '' : [],
          display: ''
        }
      );
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem(project);
      } else {
        const lsi = JSON.parse(localStorage.getItem(project));
        localStorage.setItem(
          project,
          JSON.stringify({
            ...lsi,
            [key]: newValue
          })
        );
      }
    });
  };

const SaveInitedOffline =
  () =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem('OfflineInited');
    // eslint-disable-next-line
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (typeof newValue === 'undefined') {
        localStorage.removeItem('OfflineInited');
      } else {
        localStorage.setItem('OfflineInited', JSON.stringify(newValue));
      }
    });
  };
export const _HeaderTitle = atom({
  key: 'HeaderTitle',
  default: 'PreFab Order Form'
});
export const _AppVersion = atom({
  key: 'AppVersion',
  default: '2.0.11'
});
export const _ShowChangelogModal = atom({
  key: 'ShowChangelogModal',
  default: false
});
export const _InitedOffline = atom({
  key: 'InitedOffline',
  default: { value: false, lastInited: new Date().toJSON() },
  effects: [SaveInitedOffline()]
});
export const _ServiceWorkerUpdate = atom({
  key: 'ServiceWorkerUpdate',
  default: false
});
export const _InitingOffline = atom({
  key: 'InitingOffline',
  default: false
});
export const _CurrentFilter = atom({
  key: 'CurrentFilter',
  default: {},
  effects_UNSTABLE: [localStorageEffect('OTD', 'RequestListFilter')]
});
export const _CurrentStatus = atom({
  key: 'CurrentStatus',
  default: -1
});
export const _CurrentJob = atom({
  key: 'CurrentJob',
  default: -1
});
export const _CurrentOrderNumber = atom({
  key: 'CurrentOrderNumber',
  default: -1
});
export const _CurrentSort = atom({
  key: 'CurrentSort',
  default: {
    sort: null,
    direction: null
  }
});
